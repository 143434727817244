<script>
import axios from 'axios';

export default {
  data() {
    return {
      from: '',
      to: '',
      messages: []
    };
  },
  created() {
    // Выполнение запроса при первой загрузке страницы
    this.fetchMessages();
  },
  methods: {
    async fetchMessages() {
      try {
        const params = {};

        // Добавление параметров только если они заданы
        if (this.from) {
          params.from = this.from;
        }
        if (this.to) {
          params.to = this.to;
        }
        const response = await axios.get(`${window.location.origin}/api/report`, {params});
        this.messages = response.data.messages;
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  }
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
            v-model="from"
            label="From Date"
            type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            v-model="to"
            label="To Date"
            type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn @click="fetchMessages" color="primary">Fetch Messages</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-for="message in messages" :key="message.id">
        <v-card

            :prepend-avatar="'/files/avatars/' + message.chat.photo"
            class="mx-auto"
            :title="message.chat.name"
            :subtitle="message.receivedAt"
        >
          <v-card-text>
            <pre style="white-space: pre-wrap; word-wrap: break-word;">{{ message.content }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="teal-accent-4"
                text="Перейти в канал"
                :href="'https://t.me/' + message.chat.name + '/' + message.externalId"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>