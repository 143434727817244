<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>Message Viewer</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <MessageList />
    </v-main>
  </v-app>
</template>

<script>
import MessageList from './components/MessageList.vue';

export default {
  components: {
    MessageList
  }
};
</script>
